@import './colors.scss';
@import './border-radius.scss';
@import './variables.scss';

// Bootstrap configuration before Bootstrap is imported
$border-radius: var(--border-radius);
$border-radius-sm: var(--border-radius);
$border-radius-lg: var(--border-radius);

$box-shadow: var(--box-shadow);

// No max width except for xl.
$container-max-widths: (
    xl: 1140px,
);

$border-color: var(--border-color);

// Links

$link-color: var(--link-color);
$link-hover-color: var(--link-hover-color);

// Forms

$form-check-input-margin-y: var(--form-check-input-margin-y);
$form-feedback-font-size: 0.75rem;
$input-btn-focus-width: 2px;
// The default focus ring for buttons is very hard to see, raise opacity.
// We only show the focus ring when using the keyboard, when the focus ring
// should be clearly visible.
$btn-focus-box-shadow: var(--focus-box-shadow);
$btn-link-disabled-color: var(--btn-link-disabled-color);
$btn-padding-y-sm: var(--btn-padding-y-sm);

// Forms don't manipulate the colors at compile time,
// which is why we can use CSS variables for theming here
// That's nice because the forms theming CSS would otherwise
// be way more complex than it is for other components
$input-bg: var(--input-bg);
$input-disabled-bg: var(--input-disabled-bg);
$input-border-color: var(--input-border-color);
$input-color: var(--input-color);
$input-placeholder-color: var(--input-placeholder-color);
$input-group-addon-color: var(--input-group-addon-color);
$input-group-addon-bg: var(--input-group-addon-bg);
$input-group-addon-border-color: var(--input-group-addon-border-color);
$input-focus-border-color: var(--input-focus-border-color);
$input-focus-box-shadow: var(--input-focus-box-shadow);

// Custom Selects
$custom-select-bg-size: 16px 16px;
$custom-select-disabled-bg: var(--input-disabled-bg);
$custom-select-focus-box-shadow: var(--input-focus-box-shadow);
// Icon: mdi-react/ChevronDownIcon
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$gray-06}' viewBox='0 0 24 24'><path d='M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42z'/></svg>");
// Hide feedback icon for custom-select
$custom-select-feedback-icon-size: 0;

// Disable all Bootstrap transitions
$enable-transitions: false;

/* stylelint-disable @sourcegraph/no-restricted-imports */
// Apply static variables before Bootstrap imports.
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/utilities';

// Modified in `./buttons.scss`
@import 'bootstrap/scss/buttons';
/* stylelint-enable @sourcegraph/no-restricted-imports */

@import 'wildcard/src/global-styles/breakpoints';
@import 'shared/src/global-styles/icons';
@import './background';
@import './dropdown';
@import './meter';
@import './popover';
@import './nav';
@import './list-group';
@import './custom-forms';
@import './typography';
@import './tables';
@import './code';
@import './buttons';
@import './button-group';
@import './input-group';
@import './forms';
@import './tabs';
@import './progress';
@import './collapse';
@import './grid';

* {
    box-sizing: border-box;
}

// Show a focus ring when performing keyboard navigation. Uses the polyfill at
// https://github.com/WICG/focus-visible because few browsers support :focus-visible.
:focus:not(:focus-visible) {
    outline: none;
}
:focus-visible {
    outline: 0;
    box-shadow: var(--focus-box-shadow);
}

.cursor-pointer,
input[type='radio'],
input[type='checkbox'] {
    &:not(:disabled) {
        cursor: pointer;
    }
}
