:root {
    --monospace-font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    --lead-font-size: calc(var(--font-size-base) * 1.25);
    --hr-margin-y: 0.25rem;
    --line-height-typography: 1.2;
}

h1 {
    font-size: 1.625rem; // 26px
    font-weight: 600;
    letter-spacing: (0.5/26) + em;
    margin: 0 0 1rem;
    line-height: var(--line-height-typography);
}

h2 {
    font-size: 1.25rem; // 20px
    font-weight: 600;
    letter-spacing: (0.25/20) + em;
    line-height: var(--line-height-typography);
}

h3 {
    font-size: 1rem; // 16px
    font-weight: 600;
    letter-spacing: (0.25/16) + em;
    line-height: var(--line-height-typography);
}

h4 {
    font-size: 0.875rem; // 14px
    font-weight: 500;
    letter-spacing: (0.25/14) + em;
    line-height: var(--line-height-typography);
}

h5 {
    font-size: 0.625rem; // 10px
    font-weight: 600;
    letter-spacing: (0.5/10) + em;
    text-transform: uppercase;
    margin: 0;
    line-height: var(--line-height-typography);
}

h6 {
    font-size: 0.625rem;
    font-weight: 400;
    line-height: var(--line-height-typography);
}

%small {
    // Use string interpolation and calc() to avoid using Sass own max() function.
    // See: https://github.com/sass/node-sass/issues/2815#issuecomment-575926329
    font-size: calc(min(0.75rem, #{(11/12)}em));
    line-height: 1rem;
}

// Body / Small / Regular
small,
.small {
    @extend %small;
    // Bootstrap applies `font-weight: 400;` to `small` element,
    // but we want to control `font-weight` only with `strong` or `.font-weight-medium`.
    font-weight: inherit;
}

sup,
sub {
    font-size: 0.5em;
}
sup {
    top: -1em;
}

legend {
    font-size: 1rem;
}

// Body / Base / Medium
.font-weight-medium {
    font-weight: 500;
}

// Body / Base / Strong
strong {
    font-weight: 600;
}

// Input / Small
.form-control-sm,
%form-control-sm,
.custom-select-sm,
%custom-select-sm {
    font-size: var(--input-font-size-sm);
    line-height: 1rem;
}

// Label / Base
label,
%label-base {
    font-weight: 500;
    letter-spacing: -(0.1/14) + em;
}

label small,
%label-base-small {
    @extend %small;
    letter-spacing: -(0.25/12) + em;
}

// Label / Uppercase
label.text-uppercase,
%label-uppercase {
    font-size: 0.75rem;
    letter-spacing: (0.25/14) + em;
}

label.text-uppercase small,
%label-uppercase-small {
    font-size: 0.75rem;
    letter-spacing: -(0.25/12) + em;
}

// Make sure text color utilities are theme-aware
.theme-light,
.theme-dark {
    @each $color, $value in $theme-colors {
        @include text-emphasis-variant('.text-#{$color}', $value, true);
    }
}

.list-dashed {
    list-style: none;
    position: relative;
    > li::before {
        content: '–';
        position: absolute;
        width: 2.5rem;
        left: 0;
        // stylelint-disable-next-line declaration-property-unit-allowed-list
        padding-right: 0.5em;
        text-align: right;
    }
}

hr {
    margin-top: var(--hr-margin-y);
    margin-bottom: var(--hr-margin-y);
    border: 0;
    border-top: 1px solid var(--border-color);
}

mark,
.mark {
    // stylelint-disable-next-line declaration-property-unit-allowed-list
    padding: 0.2em;
    background-color: var(--mark-bg);
}

.lead {
    font-size: var(--lead-font-size);
    font-weight: 300;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: 0.5rem;
    }
}
